var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',_vm._l((_vm.initData),function(item,index){return _c('b-col',{key:index,class:{
        'my-1': item.type != null && item.text != null
      },attrs:{"md":item.md || 6}},[(item.type == 'upload')?[_c('div',{staticClass:"mb-5"},_vm._l((item.list),function(x,i){return _c('b-row',{key:i},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"search-flex",attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":("Invoice No." + (i + 1))}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":'text',"placeholder":_vm.$t('page.please_choose_input'),"disabled":x.invoice_status == 2},model:{value:(x.invoice_no),callback:function ($$v) {_vm.$set(x, "invoice_no", $$v)},expression:"x.invoice_no"}})],1)],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"search-flex",attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"VAT Refund?"}},[_c('sv-select',{attrs:{"placeholder":_vm.$t('page.please_choose'),"options":_vm.options.refundable,"reduce":function (options) { return options.value; },"disabled":x.invoice_status == 2,"label":"label"},model:{value:(x.refundable),callback:function ($$v) {_vm.$set(x, "refundable", $$v)},expression:"x.refundable"}})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('div',{staticClass:"action"},[_c('p',{staticClass:"file_name mr-2"},[_vm._v(_vm._s(x.file_name))]),_c('b-button',{staticClass:"btn upload-check mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return item.uploadCheck(x.purchase_invoice_file_id)}}},[_vm._v(" View ")]),_c('b-button',{staticClass:"btn upload-delete",attrs:{"variant":"danger","disabled":x.invoice_status != 0},on:{"click":function($event){return item.uploadDelete(
                      x.purchase_invoice_id,
                      x.purchase_invoice_file_id
                    )}}},[_vm._v(" Delete ")])],1)])],1)}),1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"search-flex",attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":""}},[_c('b-form-file',{staticClass:"exhibit-input",attrs:{"disabled":item.state == 'uploading' ||
                    (_vm.initData.upload.list.length > 0 &&
                      _vm.initData.upload.list[0].invoice_status == 2),"accept":item.accept,"placeholder":"Upload","multiple":""},on:{"input":function($event){return item.input($event, item)}},scopedSlots:_vm._u([{key:"file-name",fn:function(ref){return [_c('div',[_vm._v(" Upload "),(item.state == 'uploading')?_c('b-icon',{staticClass:"counterclockwise",attrs:{"icon":"arrow-counterclockwise","animation":"spin-reverse","font-scale":"1.2"}}):_vm._e()],1)]}}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)],1)],1)]:_vm._e(),(
          item.type != null && item.show != false && item.type != 'upload'
        )?_c('b-form-group',{staticClass:"search-flex",class:[{ required: item.required }, item.class],attrs:{"label":item.text,"id":item.id,"label-for":'form-' + index,"label-cols-sm":"4","label-cols-lg":"3"}},[(item.type == 'input')?[_c('b-input-group',{class:{ no_r: !item.icon },scopedSlots:_vm._u([{key:"append",fn:function(){return [(item.icon || item.icon_text || item.icon_color)?_c('b-input-group-text',[(item.icon)?_c('b-icon',{attrs:{"icon":item.icon}}):_vm._e(),_vm._v(" "+_vm._s(item.icon_text)+" "),(item.icon_color && item.icon_color.indexOf('#') >= 0)?_c('div',{staticClass:"color-box",style:('background:' + item.icon_color)}):_vm._e(),(item.icon_color && item.icon_color.indexOf('#') < 0)?_c('div',{staticClass:"color-box",style:(("background:url(" + (item.icon_color) + ");background-size: 100%;"))}):_vm._e()],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":item.input_type || 'text',"min":item.min,"max":item.max,"placeholder":item.disabled
                  ? ''
                  : item.placeholder || _vm.$t('page.please_choose_input'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:(item.type == 'select')?[(item.options == 0)?_c('SelectLoading',{attrs:{"size":0.7}}):_vm._e(),_c('sv-select',{attrs:{"disabled":item.options == 0 || item.disabled,"placeholder":item.disabled
                ? ''
                : item.placeholder || _vm.$t('page.please_choose'),"options":item.options,"reduce":function (options) { return options.value; },"multiple":item.multiple,"selectable":function (options) { return !options.selectable; },"label":"label"},scopedSlots:_vm._u([{key:"option",fn:function(option){return (option.index)?[_c('div',{staticClass:"d-center",class:'level_' + option.index},[(option.index == 2)?_c('span',[_vm._v("--")]):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")])]:undefined}}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:_vm._e(),(item.type == 'textarea')?[_c('b-form-textarea',{attrs:{"id":item.id,"rows":item.rows,"placeholder":item.disabled
                ? ''
                : item.placeholder || _vm.$t('page.please_choose_textarea'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:_vm._e()],2):_vm._e()],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }