<template>
  <div>
    <b-row>
      <b-col
        :md="item.md || 6"
        v-for="(item, index) in initData"
        :key="index"
        :class="{
          'my-1': item.type != null && item.text != null
        }"
      >
        <template v-if="item.type == 'upload'">
          <div class="mb-5">
            <b-row v-for="(x, i) in item.list" :key="i">
              <!-- <template v-if="x"> -->
              <b-col md="3">
                <b-form-group
                  class="search-flex"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  :label="`Invoice No.${i + 1}`"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="x.invoice_no"
                      :type="'text'"
                      :placeholder="$t('page.please_choose_input')"
                      :disabled="x.invoice_status == 2"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  class="search-flex"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label="VAT Refund?"
                >
                  <!-- {{ options.refundable }} -->
                  <sv-select
                    v-model="x.refundable"
                    :placeholder="$t('page.please_choose')"
                    :options="options.refundable"
                    :reduce="options => options.value"
                    :disabled="x.invoice_status == 2"
                    label="label"
                  >
                    <!--  -->
                  </sv-select>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <div class="action">
                  <p class="file_name mr-2">{{ x.file_name }}</p>
                  <b-button
                    variant="primary"
                    class="btn upload-check mr-2"
                    @click="item.uploadCheck(x.purchase_invoice_file_id)"
                  >
                    View
                  </b-button>
                  <b-button
                    variant="danger"
                    class="btn upload-delete"
                    :disabled="x.invoice_status != 0"
                    @click="
                      item.uploadDelete(
                        x.purchase_invoice_id,
                        x.purchase_invoice_file_id
                      )
                    "
                  >
                    Delete
                  </b-button>
                </div>
              </b-col>
              <!-- </template> -->
            </b-row>
          </div>
          <b-row>
            <b-col md="3">
              <b-form-group
                class="search-flex"
                label-cols-sm="4"
                label-cols-lg="3"
                label=""
                ><b-form-file
                  v-model="item.model"
                  :disabled="
                    item.state == 'uploading' ||
                      (initData.upload.list.length > 0 &&
                        initData.upload.list[0].invoice_status == 2)
                  "
                  :accept="item.accept"
                  placeholder="Upload"
                  class="exhibit-input"
                  multiple
                  @input="item.input($event, item)"
                >
                  <template slot="file-name" slot-scope="{}">
                    <div>
                      Upload
                      <b-icon
                        v-if="item.state == 'uploading'"
                        class="counterclockwise"
                        icon="arrow-counterclockwise"
                        animation="spin-reverse"
                        font-scale="1.2"
                      >
                      </b-icon>
                    </div>
                  </template> </b-form-file
              ></b-form-group>
            </b-col>
          </b-row>
        </template>
        <b-form-group
          v-if="
            item.type != null && item.show != false && item.type != 'upload'
          "
          :label="item.text"
          :id="item.id"
          :label-for="'form-' + index"
          class="search-flex"
          v-bind:class="[{ required: item.required }, item.class]"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <!-- input -->
          <template v-if="item.type == 'input'">
            <b-input-group :class="{ no_r: !item.icon }">
              <template v-slot:append>
                <b-input-group-text
                  v-if="item.icon || item.icon_text || item.icon_color"
                >
                  <b-icon v-if="item.icon" :icon="item.icon"></b-icon>
                  {{ item.icon_text }}
                  <div
                    v-if="item.icon_color && item.icon_color.indexOf('#') >= 0"
                    class="color-box"
                    :style="'background:' + item.icon_color"
                  ></div>
                  <div
                    v-if="item.icon_color && item.icon_color.indexOf('#') < 0"
                    class="color-box"
                    :style="
                      `background:url(${item.icon_color});background-size: 100%;`
                    "
                  ></div>
                </b-input-group-text>
              </template>

              <b-form-input
                v-model="item.model"
                :type="item.input_type || 'text'"
                :min="item.min"
                :max="item.max"
                :placeholder="
                  item.disabled
                    ? ''
                    : item.placeholder || $t('page.please_choose_input')
                "
                :disabled="item.disabled"
              ></b-form-input>
            </b-input-group>
          </template>
          <!-- 下拉框 -->
          <template v-else-if="item.type == 'select'">
            <SelectLoading v-if="item.options == 0" :size="0.7"></SelectLoading>
            <sv-select
              v-model="item.model"
              :disabled="item.options == 0 || item.disabled"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose')
              "
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.selectable"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>
          </template>

          <template v-if="item.type == 'textarea'">
            <b-form-textarea
              :id="item.id"
              :rows="item.rows"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose_textarea')
              "
              :disabled="item.disabled"
              v-model="item.model"
            ></b-form-textarea>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import ReferenceLink from '@/components/ReferenceLink/Index';
// import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
// import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import apiBus from '@/common/apiBus/index';
export default {
  name: 'PurchaseoOverviewInvoiceDocument',

  data() {
    return {
      disabled: false
    };
  },
  props: {
    initData: Object,
    options: Object,
    initDataFn: Function,
    formError: Boolean
  },
  inheritAttrs: false,
  components: {},
  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped>
.image-box .bv-no-focus-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-width: 100%;
  max-height: 170px;
}
.upload-check {
  padding: 4px 10px !important;
  line-height: 13px !important;
  font-size: 13px;
  color: #666 !important;
  background-color: #e1e5ec;
  border-color: #e1e5ec;
  margin-bottom: 0 !important;
  border-left: 4px solid #00a0e9 !important;
  background-color: #e1e5ec;
}
.upload-delete {
  padding: 4px 10px !important;
  line-height: 13px !important;
  font-size: 13px;
  color: #666 !important;
  background-color: #e1e5ec;
  border-color: #e1e5ec;
  margin-bottom: 0 !important;
  border-left: 4px solid #df201f !important;
  background-color: #e1e5ec;
}
.upload-delete:not(.disabled):hover,
.upload-check:not(.disabled):hover,
.upload-delete:not(.disabled):active,
.upload-check:not(.disabled):active {
  color: #666 !important;
  background-color: #c2cad8 !important;
  border-top-color: #c2cad8 !important;
  border-bottom-color: #c2cad8 !important;
  border-right-color: #c2cad8 !important;
}
.action {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.action .file_name {
  margin-bottom: 0;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  whitewhite-space: nowrap;
}
</style>
